<template>
    <div>
        <v-tooltip  content-class="white--tooltip" left>
            <template v-slot:activator="{ on, attrs }">
                <v-btn fab depressed small v-bind="attrs" v-on="on" :color="'white'" @click="(mode == 'move' && foldersAvailable && foldersAvailable[0] && foldersAvailable[0].children.length == 0) ? '' : openMoveDialog = true">
                    <v-icon size="20" :color="mode == 'move' ? 'grey' : 'success'">{{mode == 'move' ? '$arrows_l' : '$trasharrowup_d'}}</v-icon>
                </v-btn>
            </template>
            <span>{{(mode == 'move' && foldersAvailable && foldersAvailable[0] && foldersAvailable[0].children.length == 0) ? $t('noFoldersAvailable') : (mode == 'move' ? $t('moveItem') : $t('restoreItem'))}}</span>
        </v-tooltip>
        <v-dialog width="400" scrollable v-model="openMoveDialog">
            <v-card class="pa-2">
                <v-card-title>
                    {{ mode == 'move' ? $t('moveItemTitle') : $t('restoreItemTitle')}}
                </v-card-title>
                <v-card-text class="pb-0">
                    <v-treeview
                        v-if="openMoveDialog"
                        :items="foldersAvailable"
                        :expand-icon="'$dropdown'"
                        v-model="tree"
                        :active="tree"
                        activatable
                        hoverable
                        :item-key="'id'"
                        item-text="title"
                        open-all
                        @update:active="onOpen"
                        :key="'treeMove_'+treeKeyEdit"
                        ref="treeSelectFolder"
                    >   
                        <template v-slot:label="{ item, open }">
                            <div v-if="!item.hasOwnProperty('root')">
                                {{ item.title }}
                            </div>
                            <div v-else>
                                {{ $t('rootfolder') }}
                            </div>
                        </template>
                        <template v-slot:prepend="{ item, open }">
                            <div class="folderIcon mr-2" v-if="!item.hasOwnProperty('root')">
                                <v-icon  color="#F8D775" size="18">
                                    {{ open ? '$folderopen_d' : '$folder_d' }}
                                </v-icon>
                                <v-avatar size="14" class="lightgrey avatarFolder" v-if="item.is_editable && item.private && item.holder && item.holder[0] && item.holder[0] != userEntity">
                                    <v-icon size="10" :color="'darkgrey'">
                                        {{ '$usersecret_d' }}
                                    </v-icon>
                                </v-avatar>
                            </div>
                            <div v-else>
                                <v-icon size="18">
                                    {{ '$home_d' }}
                                </v-icon>
                            </div>
                        </template>
                    </v-treeview>
                </v-card-text>
                <v-card-actions class="mt-2 justify-end">
                    <v-btn depressed small :loading="loading" color="success" @click="moveItem">
                        {{mode == 'move' ? $t('move') : $t('restore')}}
                    </v-btn>
                    <v-btn depressed small :loading="loading" color="primary" @click="openMoveDialog = false">
                        {{$t('cancel')}}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import GenericDataService from '@/services/GenericDataService'
export default {
    name: "moveMediaComponent",
    props: ["item", "mode"],
    data() {
        return {
            loading:false,
            openMoveDialog: false,
            foldersAvailable: [], 
            tree: [],    
            newFolderId: null,
            treeKeyEdit:0
        }
    },
    watch: {
        // openMoveDialog(value){
        //     if(value){
        //         this.$nextTick(() => {
        //             GenericDataService.postData("mediatheque/getFolderList?onlyEditable=true").then((response) => {
        //                 this.foldersAvailable = [response.data.data.folders];
        //                 console.log(response.data.data.folders)
        //                 this.treeKeyEdit++;
        //             })
        //         })
        //     }
        // }
    },
    created() {
        GenericDataService.postData("mediatheque/getFolderList?onlyEditable=true").then((response) => {
            this.foldersAvailable = [response.data.data.folders];
            this.treeKeyEdit++;
        })
    },
    computed: {
        userEntity(){
            return this.$store.state.auth.currentUser.profile.entity_id[0]
        },
    },
    methods: {
        onOpen(value){
            this.newFolderId = value[0];
            // this.tree = value;
        },
        moveItem(){
                this.loading = true;
                let url = "mediatheque/updateMedia"
                if(this.mode == 'restore')
                    url = "mediatheque/restoreMedia"

                GenericDataService.postData(url, {id: this.item.id, folder_id:this.newFolderId}).then((response) => {
                    this.$emit("movedItem", this.item);
                    setTimeout(() => {
                        this.loading = false;
                        this.openMoveDialog = false;
                    }, 500);         
                })  
        }
    },
}
</script>
<style lang="scss">
.v-treeview-node__root {
        cursor:pointer;
    }
</style>