<template>
    <div>
        <v-tooltip  content-class="white--tooltip" left>
            <template v-slot:activator="{ on, attrs }">
                <v-btn fab depressed v-bind="attrs" v-on="on" small color="white" @click="openEditDialog = true">
                    <v-icon size="20" color="grey">$pensquare_d</v-icon>
                </v-btn>
            </template>
            <span>{{item && item.is_folder ? $t('editFolder') : $t('editItem')}}</span>
        </v-tooltip>
        
        <v-dialog width="400" v-model="openEditDialog">
            <v-card class="pa-2">
                <v-card-title>
                    {{item && item.is_folder ? $t('editFolderTitle') : $t('editMediaTitle')}}
                </v-card-title>
                <v-card-text class="pb-0">
                    <v-form v-model="validFormEditItem" ref="editItemForm">
                        <v-text-field v-model="fileName" required :rules="[v => v != '' ? true : $t('fileNameError')]" :placeholder="$t('fileName')" filled flat dense solo class="mb-1 mt-1 input-form">
                            <template v-slot:label>
                                {{$t('fileName')}}
                                <span class="red--text">*</span>
                            </template>
                        </v-text-field>
                        <v-checkbox v-if="item.is_folder" hide-details v-model="folderPublic" :label="$t('publicFolder')" class="mt-0"></v-checkbox>
                        <p class="ml-8" v-if="item.is_folder">{{$t('publicBoxExplanation')}}</p>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-btn depressed small :loading="loading" color="primary" @click="editItem">
                        {{$t('edit')}}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import GenericDataService from '@/services/GenericDataService'
export default {
    name: "editItemComponent",
    props: ["itemName", "item"],
    data() {
        return {
            loading:false,
            validFormEditItem: false,
            fileName: "" ,
            folderPublic: false,
            openEditDialog: false        
        }
    },
    watch: {
        itemName:{
            immediate: true, 
            handler (val, oldVal) {
                this.fileName = val;
            }
        }, item:{
            immediate: true, 
            handler (val, oldVal) {
                if(val.is_folder)
                    this.folderPublic = !val.private;
            }
        }
    },
    methods: {
        editItem(){
            if(this.$refs.editItemForm.validate()){
                this.loading = true;
                this.$emit("editItem", this.fileName, !this.folderPublic);
                this.validFormEditItem = false;   
                setTimeout(() => {
                    this.loading = false;
                    this.openEditDialog = false;
                }, 500);         
            }
        }
    },
}
</script>