<template>
    <v-dialog 
        scrollable
        persistent
        fullscreen 
        transition="dialog-bottom-transition"
        v-model="openDialogMediatheque"
        class="mediathequePopup"
         >
        <v-card tile>
           <v-toolbar flat dark class="bg-gradient">
                <v-btn
                depressed
                color="white"
                class="square mr-2"
                text
                @click.native="closeDialog"
            >
                <v-icon dark small>$prev</v-icon>
            </v-btn>
        
            <v-toolbar-title>{{ title }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn depressed outlined color="white" class="mr-3" @click="closeDialog">
                {{$t('cancelLabel')}}
            </v-btn>
            <v-btn depressed outlined color="white" @click="saveUrl">
                {{$t('selectMediaLabel')}}
            </v-btn>
            </v-toolbar>
            <v-card-text  style="height:100%; padding:0; padding-left:12px; padding-right:12px; padding-top:12px;">
                <div style="position:relative; height:calc(100% + 12px);">
                    <mediathequeComponent id="test" type="selector" :multiple="multiple" :value="value" :accept="accept" @selectMedia="selectMedia" @unselectMedia="unselectMedia"/>
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>
import GenericDataService from '@/services/GenericDataService'
import mediathequeComponent from '../mediathequeComponent/mediathequeComponent.vue';
export default {
    name:"MediathequeV2DialogComponent",
    components: {
        mediathequeComponent
    },
    props:['openDialogMediatheque', 'value', "prefilters", "multiple", "upload", "availableFilters", "returnUrl", "accept"],
    data() {
        return {
            publicPath: process.env.BASE_URL,
            title:'Sélectionner un média', 
            filters: {}, 
            loading:false,
            selectedMedias: [],
            imageElement: null
        }
    },
    mounted() {
        if(this.prefilters) {
            this.filters = Object.assign({}, this.prefilters);
        }
        if(this.returnUrl) {
            this.selectedMedias = this.value ? (this.multiple ? this.value.map(function(media) { return {"url": media}; }) : [{"url": this.value}]) : [];
        } else {
            this.selectedMedias = this.value ? (this.multiple ? this.value : [this.value]) : [];
        }
    },
    computed: {
        mediaUrl(){
            return this.$store.state.auth.currentUser.accountParameters.mediaUrl
        },
        apiKey(){
            return this.$store.state.auth.currentUser.accountParameters.mediaApiKey
        },
    },
    methods: {
        selectMedia(media){
            if(this.multiple)
                this.selectedMedias.push(media);
            else
                this.selectedMedias = [media];
        },
        unselectMedia(media){
            this.selectedMedias = this.selectedMedias.filter(function(item) { return item.url !== media.url; });
        },
        open(element) {
            this.imageElement = element;
            this.$emit('openDialogMedia')
        },
        closeDialog(){
            this.from = 0
            this.$emit('close')
        },
        saveUrl() {
            if(this.multiple) {
                if(this.returnUrl) {
                    this.$emit('input', this.selectedMedias.map(function(media) { return media.url+"?t="+Date.now(); }));
                    
                } else {
                    this.$emit('input', this.selectedMedias);                    
                }
            } else {
                if (this.returnUrl) { 
                    this.$emit('input', (this.selectedMedias && this.selectedMedias.length ? this.selectedMedias[0].url+"?t="+Date.now() : ""), this.imageElement);
                } else {
                    this.$emit('input', this.selectedMedias && this.selectedMedias.length ? this.selectedMedias[0] : {});
                }
            }

            // Remise à zéro du composant
            //this.selectedMedias = [];

            this.$emit('close');
        },
    },
}
</script>
<style lang="scss">
.dropDiv {
        position:absolute;
        height:100%;
        width:100%;
        background-color:rgba(0,0,0,0.5);
        z-index:1200;
        display:flex;
        align-items: center;
        justify-content: center;
        div{
            font-size:34px;
            pointer-events:none;
            color:white
        }
    }

    .errorDiv {
        font-size:24px;
        line-height: 32px;
    }
    .file-input {
        display: none
    }
    .cardText {
        position:relative;
    }
    .loaderUpload {
        width: 100%;
        height: 100%;
        position:absolute;
        display:flex;
        justify-content: center;
        align-items: center;
        z-index:1000;
        background-color:rgba(255,255,255,0.7)
    }
    .hoverCard {
        position:absolute;
        pointer-events: none;
        height:90%;
        width:90%;
        top:5%;
        left:5%;
        display:flex;
        justify-content: center;
        align-items: center;
        border: 2px dashed var(--v-primary-base) !important;
        background-color: rgba(255,255,255,0.7);
        z-index: 1000;
        div {
            font-size:24px;
            font-weight: bold;
        }
    }
    .required {
    label.v-label:after {
      content: '*';
      color: var(--v-error-base);
      margin-left: 5px;
    }
  }
    .mediathequeFooter {
        z-index: 1;
        position: fixed;
        bottom: 0;
        width: 100%;
        background-color: #FFF;
        height:80px;
        display:flex;
        align-items:center;
        justify-content: flex-end;
        border-top: 1px solid #CCC;
    }
    .filters {
        display:flex;
        align-items: center;
        justify-content: space-between;
    }

    .thumbGrid {
        display:flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-wrap: wrap;
        .thumbnail {
            position:relative;
            border: 2px solid transparent;
            cursor: pointer;
            .activeHover {
                position:absolute;
                width:100%;
                height:100%;
                display:flex;
                align-items: center;
                justify-content: center;
                background-color:var(--v-primary-base) !important;
                opacity:0;
                z-index:1;
            }
            .upload {
                width:300px;
                height:200px;
                display:flex;
                align-items: center;
                justify-content: center;
                background-color: lightgrey !important;
                opacity:1;
            }
            &.transparent {
                pointer-events: none;
                .upload {
                    background-color: transparent !important;
                }
                &:hover {
                    border: none !important;
                }
            }
            &:hover {
                border: 2px solid var(--v-primary-base) !important;
            }
            &.active {
                border: 2px solid var(--v-primary-base) !important;
                .activeHover {
                    opacity:0.5;
                }
            }
            
        }
        
    }
</style>