<template>
    <div>
        <v-tooltip  content-class="white--tooltip" left>
            <template v-slot:activator="{ on, attrs }">
                <v-btn fab depressed small v-bind="attrs" v-on="on" @click="openCreateFolderDialog = true">
                    <v-icon size="20" color="grey">$folderplus_d</v-icon>
                </v-btn>
            </template>
            <span>{{$t('createFolder')}}</span>
        </v-tooltip>
       
        <v-dialog width="400" v-model="openCreateFolderDialog">
            <v-card class="pa-2">
                <v-card-title>{{ $t('createFolder') }}</v-card-title>
                <v-card-text class="pb-0">
                    <v-form v-model="validFormCreateFolder" ref="createFolderForm">
                        <v-text-field v-model="folderName" required :rules="[v => v != '' ? true : $t('folderNameError')]" :placeholder="$t('folderName')" filled flat dense solo class="mb-1 mt-1 input-form">
                            <template v-slot:label>
                                {{$t('folderName')}}
                                <span class="red--text">*</span>
                            </template>
                        </v-text-field>
                        <v-checkbox hide-details v-model="folderPublic" :label="$t('publicFolder')" class="mt-0"></v-checkbox>
                        <p class="ml-8">{{$t('publicBoxExplanation')}}</p>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-btn depressed small color="primary" @click="createFolder">
                        {{$t('create')}}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import GenericDataService from '@/services/GenericDataService'
export default {
    name: "createFolderComponent",
    props: ["selectedFolderId"],
    data() {
        return {
            openCreateFolderDialog: false,
            validFormCreateFolder: false,
            folderName: "",
            folderPublic: false,
            
        }
    },
    methods: {
        createFolder(){
            if(this.$refs.createFolderForm.validate()){
                let jsonData = {
                    'title': this.folderName,
                    'private': !this.folderPublic,
                };
                if(this.selectedFolderId != "0")
                    jsonData.parent_id = this.selectedFolderId;
                
                GenericDataService.postData("mediatheque/createFolder", jsonData).then((response) => {
                    this.$emit("folderCreated", response.data);
                    this.openCreateFolderDialog = false;
                    this.folderName = "";
                    this.folderPublic = false;
                    this.validFormCreateFolder = false;
                })
               
            }
        }
    },
}
</script>