<template>
    <div>
        <v-tooltip  content-class="white--tooltip" left>
            <template v-slot:activator="{ on, attrs }">
                <v-btn fab depressed small v-bind="attrs" v-on="on" :color="type && type == 'media' ? 'white' : 'lightgrey'" @click="openDeleteDialog = true">
                    <v-icon size="20" :color="(mode && mode == 'hard') ? 'error' : 'grey'">$trash_d</v-icon>
                </v-btn>
            </template>
            <span>{{mode && mode == 'hard' ? $t('hardDeleteItem') : $t('deleteItem')}}</span>
        </v-tooltip>
        <v-dialog width="400" v-model="openDeleteDialog">
            <v-card class="pa-2">
                <v-card-title v-if="mode && mode == 'hard'">
                    {{ type && type == 'media' ? $t('hardDeleteMediaTitle') : $t('hardDeleteFolderTitle')}}
                </v-card-title>
                <v-card-title v-else>
                    {{ type && type == 'media' ? $t('deleteMediaTitle') : $t('deleteFolderTitle')}}
                </v-card-title>
                <v-card-text class="pb-0" v-if="mode && mode == 'hard'">
                    {{ type && type == 'media' ? $t('deleteMediaConfirmation') : $t('deleteFolderWarningConfirmation') }}
                </v-card-text>
                <v-card-text class="pb-0" v-else>
                    {{ type && type == 'media' ? $t('hardDeleteMediaConfirmation') : $t('hardDeleteFolderWarningConfirmation') }}
                </v-card-text>
                <v-card-actions class="mt-2 justify-end">
                    <v-btn depressed small :loading="loading" color="error" @click="deleteItem">
                        {{$t('delete')}}
                    </v-btn>
                    <v-btn depressed small :loading="loading" color="primary" @click="openDeleteDialog = false">
                        {{$t('cancel')}}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import GenericDataService from '@/services/GenericDataService'
export default {
    name: "deleteItemComponent",
    props: ["item", "type", "mode"],
    data() {
        return {
            loading:false,
            openDeleteDialog: false        
        }
    },
    methods: {
        deleteItem(){
            this.loading = true;
            if(this.mode && this.mode == 'hard')
                this.hardDelete()
            else
                this.softDelete() 
        },
        hardDelete(){
            console.log("hard delete")
            let url = "mediatheque/hardDelete"
            GenericDataService.postData(url, {id: this.item.id}).then((response) => {
                this.$emit("deletedItem", this.item);
                setTimeout(() => {
                    this.loading = false;
                    this.openDeleteDialog = false;
                }, 500);         
            })  
        },
        softDelete(){
            let url = "mediatheque/deleteItem"
            if(this.type && this.type == 'folder')
                url = "mediatheque/deleteFolder"

            GenericDataService.postData(url, {id: this.item.id}).then((response) => {
                this.$emit("deletedItem", this.item);
                setTimeout(() => {
                    this.loading = false;
                    this.openDeleteDialog = false;
                }, 500);         
            })  
        }
    },
}
</script>