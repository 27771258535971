const dateTimeFormat = {
    long: { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' },
    dayMonthYear: {year: 'numeric', month: 'long', day:'numeric', weekday: "long"},
    dayMonth: {month: 'long', day:'numeric'},
    dayMonthNumber: {month: 'numeric', day:'numeric'},
    monthYear: {month: 'long', year:'numeric'},
    dayMonthYear2: {year: 'numeric', month: 'long', day:'numeric'},
    dayMonthYear3: {month: 'long', day:'numeric', weekday: "long"}
}

export default {
    methods: {
        //Calcul du nombre de jour entre deux dates
        DaysBetween : function (StartDate, EndDate) {
            // Le nombre de millisecondes dans un jour
            const oneDay = 1000 * 60 * 60 * 24;
            // Transformation des dates en UTC pour ne pas avoir de problèmes avec les changements d'heures
            const start = Date.UTC(EndDate.getFullYear(), EndDate.getMonth(), EndDate.getDate()) + oneDay;
            const end = Date.UTC(StartDate.getFullYear(), StartDate.getMonth(), StartDate.getDate());
            return ((start - end) / oneDay);
        },
        parseDate: function(str){
            //Transformation des dates en date JS
            var dateArray = str.split("-"); 
            return new Date(dateArray[0], dateArray[1]-1, dateArray[2])
        },
        //Parse les dates du format jj/mm/aaaa en aaaa-mm-jj
        parseFrDateToUs: function(str){
            var dateArray = str.split('/')
            return dateArray[2]+"-"+dateArray[1]+"-"+dateArray[0]
        },
        //Parse les dates du format aaaa-mm-jj en jj/mm/aaaa
        parseUsDateToFr: function(str){
            if(str != ""){
                var dateArray = str.split('-')
                return dateArray[2]+"/"+dateArray[1]+"/"+dateArray[0]
            }
            return ""
        },
        parseJsDateToUs(date) {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();
        
            if (month.length < 2) 
                month = '0' + month;
            if (day.length < 2) 
                day = '0' + day;
        
            return [year, month, day].join('-');
        },
        parseFullJsDateToUs(date, withSeconds = false){
            var d = new Date(date)
            var month = (d.getMonth() + 1) < 10 ? "0"+(d.getMonth() + 1) : (d.getMonth() + 1)
            var day = d.getDate() < 10 ? "0"+d.getDate() : d.getDate()
            var year = d.getFullYear();
            var hour = d.getHours() < 10 ? "0"+d.getHours() : d.getHours() ;
            var minutes = d.getMinutes() < 10 ? "0"+d.getMinutes() : d.getMinutes();
            if(withSeconds){
                var seconds = d.getSeconds() < 10 ? "0"+d.getSeconds() : d.getSeconds();
                return [year, month, day].join('-') +" "+ [hour, minutes, seconds].join(":");
            } else {
                return [year, month, day].join('-') +" "+ [hour, minutes].join(":");
            }
            
        },
        parseFullDateToFr: function(str, withHours = true){
            var dateArray = str.split(' ')
            var date = dateArray[0];

            if(dateArray[1] && withHours) {
                var hour = dateArray[1];
                var hourArray = hour.split(':')
            }

            var dateFr = this.parseUsDateToFr(date)

            return dateFr + (hourArray ? (" " + hourArray[0] + ":" + hourArray[1]) : '');
        },
        parseFRDateToDayMonthAndHours(str){
            let date = this.parseFullDateToFr(str);
            let hours = date.split(' ')[1];
            let datewHours = date.split(' ')[0];
            let dayMonth = datewHours.split('/')[0]+"/"+datewHours.split('/')[1];

            return dayMonth+" "+hours;
        },
        //set le numero du jour de 1 a 7 (7 = dimanche) au lieu du getDay normal de 0 a 6 (ou 0 = dimanche)
        getBetterDay: function(dateObject){
            let day = dateObject.getDay();
            day == 0 ? day = 7 : day = day;
            return day
        },
        //Methode pour parser des heures en float
        parseHourToFloat : function(hour){
            const arrayHour = hour.split(':');
            const minutesToNumeric = arrayHour[1] * 100 / 60
            return parseFloat(arrayHour[0]+"."+minutesToNumeric)
        },
         //Methode pour parser des float en heures
        parseFloatToHour : function(floatHour){
            //Si entier alors on peut la renvoyer telle quelle avec :00
            if(Number.isInteger(floatHour)){
                if(floatHour < 10){
                    floatHour = "0"+floatHour
                }
                return floatHour+":00"
            } else {
                const stringFloatHour = ""+floatHour;
                const arrayFloat = stringFloatHour.split('.');
                let numericToMinutes = (arrayFloat[1] * 60 / 100)+""
                if(numericToMinutes.length == 1){
                    numericToMinutes = numericToMinutes +"0"
                }
                if(arrayFloat[0] < 10){
                    return "0"+arrayFloat[0]+":"+numericToMinutes
                } else {
                    return arrayFloat[0]+":"+numericToMinutes
                }
                
            }           
        },
        //Methode pour parser des timestamp en date / heures
        timestampToDate(timestamp, type) {
            let date = new Date(timestamp * 1000);
            return date.toLocaleDateString(undefined, dateTimeFormat[type]);
        },
        //Methode pour parser dates JS en Datetime pour SQL (string)
        parseJsDateToDatetimeSql(date) {
            let dateStr = 
                date.getFullYear() + "-" 
                + (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1)) + "-"
                + (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " "
                + (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":"
                + (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) + ":"
                + (date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds());
            return dateStr;
        },
        //Methode pour parser Datetime JS en JJ/MM/YYYY HH:MM
        parseDatetimeStringToDatetime(datetime){
            if(typeof datetime === "string")
                datetime = new Date(datetime);

            let dateStr = 
                (datetime.getDate() < 10 ? "0" + datetime.getDate() : datetime.getDate()) + "/" 
                + (datetime.getMonth() + 1 < 10 ? "0" + (datetime.getMonth() + 1) : (datetime.getMonth() + 1)) + "/"
                + datetime.getFullYear() + " "
                + (datetime.getHours() < 10 ? "0" + datetime.getHours() : datetime.getHours()) + ":"
                + (datetime.getMinutes() < 10 ? "0" + datetime.getMinutes() : datetime.getMinutes());
            return dateStr;
        },
        // Transformation des secondes en jours / heures / minutes
        getChipsTime(dueInSeconds, withSeconds = false, withoutDots = false) {
            dueInSeconds = Number(dueInSeconds < 0 ? -dueInSeconds : dueInSeconds);
    
            var d = Math.floor(dueInSeconds / (3600*24));
            var h = Math.floor(dueInSeconds % (3600*24) / 3600);
            var m = Math.floor(dueInSeconds % 3600 / 60);
            var s = Math.floor(dueInSeconds % 60);
    
            var dDisplay = d > 0 ? d + this.$t("day").charAt(0) + " " : "";
            var hDisplay = h > 0 ? (d > 0 ? " : " : "") + h + this.$t("hour").charAt(0) + " " : "";
            var mDisplay = m > 0 ? (h > 0 ? " : " : "") + m + this.$t("minute").charAt(0) + " " : "";
            var sDisplay = s > 0 ? (m > 0 ? " : " : "") + s + this.$t("second").charAt(0) + " " : "";
            
            if(withoutDots){
                var dDisplay = d > 0 ? d + this.$t("day").charAt(0) + " " : "";
                var hDisplay = h > 0 ? (d > 0 ? " " : "") + h + this.$t("hour").charAt(0) + " " : "";
                var mDisplay = m > 0 ? (h > 0 ? " " : "") + m + this.$t("minute").charAt(0) + " " : "";
                var sDisplay = s > 0 ? (m > 0 ? " " : "") + s + this.$t("second").charAt(0) + " " : "";
            }

            return dDisplay + hDisplay + mDisplay + (withSeconds ? sDisplay : "");
        },
        getHoursMinutesLeft(dueInSeconds){
            dueInSeconds = Number(dueInSeconds < 0 ? -dueInSeconds : dueInSeconds);
            var h = Math.floor(dueInSeconds % (3600*24) / 3600);
            var m = Math.floor(dueInSeconds % 3600 / 60);
            var hDisplay = h > 0 ? h + this.$t("hour").charAt(0) + "" : "";
            var mDisplay = m > 0 ? m + this.$t("minute").charAt(0) + "" : "";

            return hDisplay + mDisplay
        },
        getAfterOneDay(dueInSeconds){
            var m = Math.floor(dueInSeconds / (3600*24*31));
            var w = Math.floor(dueInSeconds / (3600*24*7));
            var d = Math.floor(dueInSeconds / (3600*24));

            var mDisplay = m +" "+ ( m == 1 ? this.$t("month") : this.$t("months"))
            var wDisplay = w +" "+ ( w == 1 ? this.$t("week") : this.$t("weeks") )
            var dDisplay = d +" "+ ( d == 1 ? this.$t("day") : this.$t("days"))

            return m > 0 ? mDisplay : (w > 0 ? wDisplay : dDisplay);
        },
        getHourMinutesFromDate(dateString){
            let time = dateString.split(' ')[1];
            let timeSplit = time.split(':');

            return timeSplit[0]+"h"+timeSplit[1];
        },
        getMonthYear(str){
            if(str != ""){
                var dateArray = str.split('-')
                return dateArray[1]+"/"+dateArray[0]
            }
            return ""
        },
        phoneNumberToClean(phoneNumber){
            if(phoneNumber && typeof phoneNumber === "string")
                return phoneNumber.trim().replace(/(\d{2})(\d{2})(\d{2})(\d{2})$/, ' $1 $2 $3 $4');
            else
                return "";
        }
    }
}